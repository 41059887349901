import { Icon } from '@/components/_atoms/Icon';
import { Typography } from '@/components/_atoms/Typography';

export const Info = () => {
  return (
    <div className="flex items-center rounded-lg bg-blue-50 py-2 pl-3 pr-4">
      <Icon name="checkmarkFilled" size={16} color="blue-500" />
      <Typography variant="body_s" color="blue" className="ml-2 flex-1">
        Jednocześnie możesz porównać maksymalnie 3 produkty. Aby porównać kolejny produkt zamień go z jednym z
        poniższych.
      </Typography>
    </div>
  );
};
