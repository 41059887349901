import { queryOptions, useQuery } from '@tanstack/react-query';

import { setCheckoutToken } from '@/logic/checkout/checkoutToken';
import { QueryKeys } from '@/network/query.types';

import { client, schemas, unwrapResponse } from '../client';

import { getCheckoutToken } from './../../logic/checkout/checkoutToken';

// Checkout

export const fetchCheckoutQueryOptions = (token: string | null) =>
  queryOptions({
    queryKey: [QueryKeys.checkout],
    queryFn: async () => {
      if (!token) {
        return { lines: [] satisfies schemas['CheckoutLine'][], lines_count: 0, total_gross_amount: '0' };
      }
      const { response, data, error } = await client.GET('/api/v1/checkout/{token}', {
        params: {
          path: {
            token,
          },
        },
      });
      if (data) {
        return data;
      }

      if (response.status === 404 || response.status === 400) {
        setCheckoutToken(null);
      }
      throw error;
    },
  });

export function useGetCheckoutQuery() {
  const token = getCheckoutToken();
  return useQuery({
    ...fetchCheckoutQueryOptions(token),
    refetchOnWindowFocus: true,
    staleTime: 3000,
  });
}

// Checkout Simple

const fetchCheckoutSimple = async () => {
  const token = getCheckoutToken();
  if (!token) {
    return { lines: [] satisfies schemas['CheckoutLine'][], lines_count: 0, total_gross_amount: '0' };
  }
  const { response, data, error } = await client.GET('/api/v1/checkout/{token}/simple', {
    params: {
      path: {
        token,
      },
    },
  });
  if (data) {
    return data;
  }

  if (response.status === 404 || response.status === 400) {
    setCheckoutToken(null);
  }
  throw error;
};

export function useGetCheckoutSimpleQuery() {
  return useQuery({
    queryKey: [QueryKeys.checkoutSimple],
    queryFn: () => fetchCheckoutSimple(),
    refetchOnWindowFocus: true,
    staleTime: 0,
  });
}

// Checkout payment methods

export const fetchCheckoutPaymentMethodsQueryOptions = () =>
  queryOptions({
    queryKey: [QueryKeys.checkoutPaymentMethods],
    queryFn: () => client.GET('/api/v1/checkout/payments/methods').then(unwrapResponse),
    staleTime: Infinity,
  });

export function useGetCheckoutPaymentMethodsQuery() {
  return useQuery(fetchCheckoutPaymentMethodsQueryOptions());
}
