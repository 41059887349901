import { useTranslations } from 'next-intl';
import { useRouter } from 'next/router';
import React from 'react';

import { ProductLink } from '@/components/ProductLink';
import { ProductCompareButton } from '@/components/ProductsListItem/components/ProductCompare';
import { TyreImage } from '@/components/TyreImage';
import { Typography } from '@/components/_atoms/Typography';
import { Button } from '@/components/_molecules/Button';
import { useModals } from '@/components/_molecules/Modals';
import { useAddToCheckout } from '@/logic/checkout/useAddToCheckout';
import { LastSeenProduct } from '@/logic/lastSeen/lastSeen.types';
import { usePrice } from '@/logic/price';
import { getProductShortName } from '@/logic/product/products.utils';
import { ApiErrorCodes, isApiError } from '@/network/api.utils';

interface Props extends LastSeenProduct {
  close: () => void;
}

export const HeaderLastSeenItem = (props: Props) => {
  const t = useTranslations();
  const { openInfoModal } = useModals();
  const router = useRouter();
  const formatPrice = usePrice();
  const { close, sku, price, tread, image, season, slug, isMain, producer, attributes, productionYear } = props;

  const addToCheckout = useAddToCheckout();
  const handleClick = async () => {
    try {
      await addToCheckout({
        sku,
        quantity: Number(4),
      });
      router.push('/koszyk');
    } catch (err) {
      if (isApiError(err) && err.data.error_code === ApiErrorCodes.createUpdateCheckout.VARIANT_SOLD_OUT) {
        openInfoModal({
          title: t('createCheckout.VARIANT_SOLD_OUT.title'),
          description: t('createCheckout.VARIANT_SOLD_OUT.description'),
          label: t('createCheckout.VARIANT_SOLD_OUT.label'),
          onConfirm: () => router.reload(),
        });
      } else {
        throw err;
      }
    } finally {
      close();
    }
  };
  return (
    <div className="group flex p-3 pr-6">
      <ProductLink slug={slug} sku={sku} isMain={isMain} onClick={close} className="mr-2 inline-flex shrink-0">
        <TyreImage
          unoptimized={image?.is_label}
          src={image?.url}
          alt={`${producer} ${tread}`}
          style={{
            width: 72,
            height: 72,
          }}
        />
      </ProductLink>
      <div className="mr-4 flex flex-1 flex-col gap-1">
        <ProductLink
          className="text-body-m transition-colors group-hover:text-text-orange-dark"
          slug={slug}
          sku={sku}
          isMain={isMain}
          onClick={close}
        >
          {t.rich('lastSeen.item.name', {
            producer,
            tread,
            strong: (msg) => <span className="font-bold">{msg}</span>,
          })}
        </ProductLink>
        <p className="text-body-s">
          {getProductShortName({
            ...attributes,
            productionYear,
          })}
        </p>
        {sku && price ? (
          <span>
            <ProductCompareButton
              data={{
                attributes,
                image,
                isMain,
                price,
                producer,
                season,
                sku,
                slug,
                tread,
                productionYear,
              }}
            />
          </span>
        ) : null}
      </div>
      <div className="flex flex-col items-end justify-between">
        <Typography as="span" variant="body_xl" bold>
          {price ? formatPrice(price) : t('productDetails.price.empty')}
        </Typography>
        {sku ? <Button onClick={handleClick} textId="lastSeen.item.addToBasket" variant="subtle" size="s" /> : null}
      </div>
    </div>
  );
};
